<template>
  <div>
    <BookmarkRow
        v-for="bookmark in bookmarks"
        :key="bookmark.id"
        :bookmark="bookmark"
        @view-bookmark="viewBookmark"
        @update-bookmark="updateBookmark"
        />
    <div class="flex items-center justify-between px-4 py-3 text-sm text-gray-600 border-b border-gray-800 cursor-pointer select-none">
      <button
        class="text-xs btn btn-fluid btn-dark"
        @click.prevent="newBookmark"
      ><fa-icon
        icon="plus"
        class="mr-1"
      /> New Bookmark</button>
    </div>
  </div>
</template>
<script>    
const BookmarkRow = () => import('./bookmarks/BookmarkRow')
const SceneGraph = () => import('@components/editor/scene/model/panels/SceneGraph')

export default {
  name: 'CamerasPanel',

  components: {
    BookmarkRow
  },

  props: {
    gui: {
      type: Object,
      required: true
    }
  },

  computed: {
    bookmarks () {
      return this.gui.bookmarks
    },

    editorCameraBookmarkInspectorEnabled () {
      return true
    }
  },

  methods: {

    updateBookmark (bookmark) {
      this.gui.updateBookmark(bookmark)
      this.$emit('settings-changed')
    },

    viewBookmark (bookmark) {
      this.gui.activateBookmark(bookmark)

      // this.$bus.$emit('editor:sidebar', {
      //   component: SceneGraph,
      //   props: {
      //     key: 'bookmark-' + bookmark.id,
      //     node: bookmark,
      //     gui: this.gui
      //   }
      // })
    },

    newBookmark () {
      let bookmark = this.gui.newBookmark()
      this.viewBookmark(bookmark)
      this.$emit('settings-changed')
    }
  }

}

</script>
